.containerWithAllLOGO{
    width: 100%;
}

.containerWithPositionLogo{
    width: 46%;
}
.selectPositionLOGO{
    width: 100%;
    cursor: pointer;
    padding: 5.5px 3.5px;
    width: 100%;
}
.containerWithRozmiarLOGO{
    width: 46%;
}
.containerWithZaladujPlik{
    margin-top: 16px;
}