.container_style_logo{
    display: flex;
    /* тут поменял   */
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: absolute;
}

.tloczenieContentContainer{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:flex-end;
}

.containerWithIMG{
    background-color: white;
    position: relative;

}
.img_center_LOGO{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;  
    height: 100%;
}

.img_center_LOGO_black{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fbfafa;
    width: 100%;  
    height: 100%;
}