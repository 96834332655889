.cameoTloczenie_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    position: absolute;
}

.cameoAndTloczenieContentContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.okienko_cameo{
    position:relative;
    width: 100%;
    height: 100%;
}

.img_Cameo{
    height: 100%;
    width: 100%;
}

.containerText{
    display:flex;
    justify-content: center;
    align-items:center;

    position:absolute;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
}


.white{
    color:#ffff;
}
.plain{
    color:rgba(0,0,0,0.5);
    text-shadow: 0.2px 0.2px 0.2px rgb(94, 93, 93);
}

.gold{
    color: #c6aa6a;

}

.silver{
    color: #babbbe;
}

.copper{
    color:#b46b3d
}

.font1-1{
    font-size: 46px;
    font-family: 'futura_ptbook';
}
.font1-2{
    font-size: 10px;
    font-family: 'futura_ptbook';

}
.font2-1{
    font-size: 16px;
    font-family: 'futura_ptbook';

}
.font2-2{
    font-size: 8px;
    font-family: 'futura_ptbook';
}

