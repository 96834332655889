.cameo_container{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    height: 100%;
}

.okienko_cameo{
    position:relative;
    width: 100%;
    height: 100%;
}
.img_Cameo{
    height: 100%;
    width: 100%;
}


