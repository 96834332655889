/* .cropper-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000000;





    position: fixed;
    top: 10%; 
    left: 0;
    width: 100%;
    height: 80%; 
    background-color: white;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10000000;
}

.cropper-modal img {
    max-width: 100%;
    max-height: 90%; 
}


.cropper-modal .cropper-container.cropper-bg {
    background-image: none !important;
} */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.77); 
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 60px;
}

.modalContent {
    width: 85%;
    height: 85%;
}

.containerWithButtonsCropPhotopanel{
    position: fixed;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
}

.buttonCropPhotopanel1{
    cursor:pointer;
    padding: 8px 25px;
    background-color: #963248;
    font-family: 'OldStandardTT';
    color: white;
    border: none;
    font-size: 14px;
    margin-right: 10px;
}
.buttonCropPhotopanel2{
    cursor:pointer;
    padding: 8px 25px;
    background-color: #963248;
    font-family: 'OldStandardTT';
    color: white;
    border: none;
    font-size: 14px;
}