.containerWithLinia3{
    display: flex;
}
.mainContainerWithTekstLines{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.containerWithTekstInfo{
    width: 35%;
    display: flex;
    flex-direction: column;
    margin-right: 4%;
}
.containerWithInputSize{
    display: flex;
    flex-direction: column;
    width: 22%;
    margin-right: 4%;
}
.containerWithFonts{
    display: flex;
    flex-direction: column;
    width: 35%;
}
.blockWithMinus{
    width: 100%;
    font-size: 27px;
    border-right: 1px solid black;
    background-color: #FEFAF6;
}
.blockWithMinus :hover{
    background-color:#F4F2F1;
}
.blockWithMinus span {
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items:center;
}

.containerWithPlus{
    width: 100%;
    font-size: 27px;
    border-left: 1px solid black;
    background-color: #FEFAF6;
}
.containerWithPlus span{
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items:center;
}

.containerWithPlus :hover{
    background-color:#F4F2F1;

}
.containerWithInputCzcionkaRozmiar{
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerWithRozmiaCzcionkiElements{
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 75%;
    background-color: white;
}
.inputLine1{
    padding: 7px 3.5px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.mainContainerWithLineHeightAndCzcionka{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.containerWithTekstCzcionkaAndLineHeight{
    display: flex;

}
.infoTekstCzcionkaOverprint{
    margin-right: 7%;
    width: 46%;
    display: flex;
    justify-content: space-between;
}
.infoTekstLineHeightOverprint{
    width: 46%;

}

.selectRozmiarCzcionkiOverprint{
    cursor: pointer;
    padding: 6.5px 3.5px;
    width: 100%;
}

.colorsOverprint{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-left: 4px;

}
.colorBlockOverprint{
    width: 100px; 
    flex-shrink: 0; 
    flex-grow: 0; 

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.blockWithPhotosColorOverprint{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.colorImageOverprint{
    width: 100px;
    height: 100px;  
    border: 1px solid black;
}
.colorImageOverprint.selected {
    border: 3px solid rgb(150, 50, 72);
    margin:0px;
}
.colorImageOverprint.notSelected {
    margin:2px;
}

.blockWithPhotosColorOverprint label {

    margin-top: 3px;  
}




.imageOptionsLokalizacjaOverprint {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.imageOptionLokalizacjaOverprint {
    cursor: pointer;
    display: flex;
    align-items: center;

}

.containerWithOneElemtLokalizacjiOverprint{
    display: flex;
    flex-direction: column;
    align-items:center;
}
.imgElementLokalizacjiOverprint{
    border: 1px solid rgb(82, 79, 79);
    width: 100px;
    height: 100px;

} 
 .imgElementLokalizacjiOverprint.selected {
    border: 3px solid rgb(150, 50, 72);
    margin: 0px;
}
.imgElementLokalizacjiOverprint.notSelected {
    margin: 2px;
}