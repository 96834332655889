.container_overprint {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    height: 100%;

}

.container_overprint_border {
    display:block;
    position:absolute;
    border: 1px solid #fee;
}


.photo_album_overprint{
    width: 100%;
    height: 100%;
}

.block_with_text_overprint{
    text-align: center;
    position: absolute; 
    width: fit-content;

}


.block_with_text_overprint p{
    margin: 0px;
    white-space: nowrap;
}


/* .Middle_Center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
} */

.Visual_Center{
    top: 25%; 
    left: 50%;
    transform: translateX(-50%);
}

.Top_Center{
    top: 1%; 
    left: 50%;
    transform: translateX(-50%);
}
.Bottom_Center{
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.Bottom_Right{
    bottom: 0;
    right: 0;
    text-align: right;
}


.Right_Center{
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    text-align: right;
}


.gold{
    color: #c6aa6a;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(at right bottom, rgb(70, 37, 35) 0px, rgb(203, 155, 81) 22%, rgb(246, 226, 122) 45%, rgb(246, 242, 192) 50%, rgb(246, 226, 122) 55%, rgb(203, 155, 81) 78%, rgb(70, 37, 35) 100%);
}
.silver{
    color: #babbbe;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}

.white{
    color:#ffff;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}



/* fonts */
.font_Autumn_in_November{
    font-family: 'Autumn_in_November';
}

.font_Ballerina_script {
    font-family: 'Ballerina_script';
}

.font_Anna_Clara {
    font-family: 'Anna_Clara';
}
.font_A_Gentle_Touch {
    font-family: 'A_Gentle_Touch';
}
.font_Argentinian_night {
    font-family: 'Argentinian_night';
}
.font_Avocado_Lime {
    font-family: 'Avocado_Lime';
}
.font_Ballerina_script {
    font-family: 'Ballerina_script';
}
.font_Champagne_limousines {
    font-family: 'Champagne_limousines';
}
.font_Charlotte {
    font-family: 'Charlotte';
}
.font_Claudia_script {
    font-family: 'Claudia_script';
}
.font_Dutch_801_xbd_bt {
    font-family: 'Dutch_801_xbd_bt';
}
.font_Endolitta_demo {
    font-family: 'Endolitta_demo';
}
.font_Exotc350BdBTBold{
    font-family: 'Exotc350BdBTBold';
}
.font_Gardenia {
    font-family: 'Gardenia';
}
.font_HARRINGT {
    font-family: 'HARRINGT';
}
.font_Hesterica {
    font-family: 'Hesterica';
}
.font_HighTOWERT {
    font-family: 'HighTOWERT';
}
.font_ITCKRIST {
    font-family: 'ITCKRIST';
}
.font_JandaHappyDay {
    font-family: 'JandaHappyDay';
}
.font_Juice_itc {
    font-family: 'Juice_itc';
}
.font_KGBeneathYourBeautifulChunk {
    font-family: 'KGBeneathYourBeautifulChunk';
}
.font_Love_moment {
    font-family: 'Love_moment';
}.font_March_lovers {
    font-family: 'March_lovers';
}
.font_Mondayline {
    font-family: 'Mondayline';
}
.font_Munira_script {
    font-family: 'Munira-script';
}
.font_My_silly_wully_girl {
    font-family: 'My_silly_wully_girl';
}
.font_Nattalia {
    font-family: 'Nattalia';
}
.font_October_moon {
    font-family: 'October_moon';
}
.font_OptimusPrinceps {
    font-family: 'OptimusPrinceps';
}
.font_RougeScript {
    font-family: 'RougeScript';
}
.font_SALSABILLA {
    font-family: 'SALSABILLA';
}
.font_SIMSUN {
    font-family: 'SIMSUN';
}
.font_Spring_is_comming {
    font-family: 'Spring_is_comming';
}
.font_stylus_bt {
    font-family: 'stylus_bt';
}
.font_Weather_sunday {
    font-family: 'Weather_sunday';
}
.font_You_make_me_smile {
    font-family: 'You_make_me_smile';
}
.font_KGNeatlyPrinted {
    font-family: 'KGNeatlyPrinted';
}
/* .font_Peoni_Pro {
    font-family: 'Peoni_Pro';
} */
/* .font_King_Basil_Lite {
    font-family: 'King_Basil_Lite ';
} */
