.containerWithTloczneiem{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.mainContainerWithFirstPartOkladki{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.images_block{
    margin-right: 12px;
    cursor: pointer;
    box-shadow: 0 0 2px 1px #0003;
}
.blockWithinfoTekst{
    display: flex;
    justify-content: space-between;
    width: 46%;
}
.images_block {
    box-shadow: 0 0 0 1px #b3b3b3;
    border: 3px solid #8c8c8c;
    border-radius: 3px;
}
.containerWithInputAndSelectOkladka{
    display: flex;
    width: 100%;
}

.inputsOkladka {
    padding: 7.5px 3.5px;
    cursor:pointer;
    width: 100%;
    box-sizing:border-box;
}
.containerTogetherokladkaInput{
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    flex-direction: column;
    width: 46%;
    margin-right: 7%;
}
.containerTogetherokladkaSelect{
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    flex-direction: column;
    width: 46%;
}
.selectChoseRozmiarCzcionki{
    font-size: 10;
    cursor:pointer;
    padding: 7px 3.5px;
    width: 100%;

}

.containerTogetherokladka{
    display: flex;
    flex-direction: column;
}

.blockPersonalizacjaColoru{
    display: flex;
    flex-direction: column;
    margin-top: 1em;
}
.blockPersonalizacjaColoru p {
    margin-top: 0px;
}






.colorContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

}

.colorBlock {
    width: 100px; 
    height: 130px; 
    flex-shrink: 0; 
    flex-grow: 0; 

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    margin-left:3px;
}

.blockWithPhotosColor{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.colorBlock.disabled {
    cursor: not-allowed; 
    opacity: 0.18; 
    pointer-events: none; 
}


.colorImage{
    width: 100px;
    height: 100px;  
    /* box-sizing: border-box;  */
    border: 1px solid black;
}
.colorImage.selected {
    border: 3px solid rgb(150, 50, 72);
    margin: 0px; 
}
.colorImage.notSelected {
    margin: 2px;
}



.blockWithPhotosColor label {
    margin-top: 5px;  
}


.imageContainer {
    display: flex;
    gap: 15px;
    margin-left: 3px;
}
  
.imageWrapper {
    display: flex;
    flex-direction: column;
    align-items:center;
    cursor:'pointer';
}
.imageWrapper img{
    border: 1px solid rgb(82, 79, 79);

}

.imageWrapper.selected img{
    border: 3px solid rgb(150, 50, 72);
    margin: 0px;
}
.imageWrapper.notSelected img{
    margin: 2px;

}
  .imageWrapper img {
    width: 100px; 
    height: 100px;
    cursor: pointer;
    /* box-sizing:border-box; */
  }
  
  .imageWrapper span {
    margin-top: 5px;
  }