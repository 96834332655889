.container_okładka{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    position: absolute;
}

.tloczenieContentContainer{
    position: relative;
    width: 100%;
    height: 100%;
}
.center_okladka{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.prawy_dol_okladka{
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: end;

}


.white{
    color:#ffff;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}

.plain{
    color: transparent;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}

.gold{
    color: #c6aa6a;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(at right bottom, rgb(70, 37, 35) 0px, rgb(203, 155, 81) 22%, rgb(246, 226, 122) 45%, rgb(246, 242, 192) 50%, rgb(246, 226, 122) 55%, rgb(203, 155, 81) 78%, rgb(70, 37, 35) 100%);
}

.silver{
    color: #babbbe;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}

.copper{
    color:#b46b3d;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}




.font1-1{
    font-size: 46px;
    font-family: 'futura_ptbook';
}
.font1-2{
    font-size: 31px;
    font-family: 'futura_ptbook';

}
.font2-1{
    font-size: 26px;
    font-family: 'futura_ptbook';

}
.font2-2{
    font-size: 18px;
    font-family: 'futura_ptbook';
} 






@media screen and (max-width: 1200px) {
    .font1-1{
        font-size: 38px;
        font-family: 'futura_ptbook';
    }  
}

@media screen and (max-width: 1000px) {
    .font1-1{
        font-size: 32px;
        font-family: 'futura_ptbook';
    }  
}

@media screen and (max-width: 650px) {
    .font1-1{
        font-size: 26px;
        font-family: 'futura_ptbook';
    }  
}

@media screen and (max-width: 400px) {
    .font1-1{
        font-size: 16px;
        font-family: 'futura_ptbook';
    }  
}



/* @media screen and (max-height: 920px) {
    .font1-1{
        font-size: 38px;
        font-family: 'futura_ptbook';
    }  
}

@media screen and (max-height: 650px) {
    .font1-1{
        font-size: 32px;
        font-family: 'futura_ptbook';
    }  
}

@media screen and (max-height: 350px) {
    .font1-1{
        font-size: 26px;
        font-family: 'futura_ptbook';
    }  
}

@media screen and (max-height: 220px) {
    .font1-1{
        font-size: 16px;
        font-family: 'futura_ptbook';
    }  
} */