.containerZRozmiaremMatrycy{
    width: 30%;
}
.selectZRozmiaremMatrycy{
    cursor: pointer;
    padding: 5.5px 3.5px;
    width: 100%;
}

.containerWithTeksFunctional{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.containerTextAreaMatryca{
    width: 46%;
}

.textAreaMatryca{
    width: 100%;
    padding: 0px;
    max-width: 100%;
    max-height: 80px;
    
}
.matrycaContainerWithLines{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.containerWithTekstInfoMatryca{
    width: 35%;
    display: flex;
    flex-direction: column;
    margin-right: 4%;
}
.inputMatrucaLine{
    padding: 7px 3.5px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}
.containerWithInputsSizeMatryca{
    display: flex;
    flex-direction: column;
    width: 22%;
    margin-right: 4%;
}

.blockWithPlusAndMinusMatryca{
    width: 40%;
    display: flex;
    justify-content: space-between;
    font-size: 27px;
}
.containerWithFontsMatryca{
    display: flex;
    flex-direction: column;
    width: 35%;
}

.selectRozmiarCzcionkiMatryca{
    cursor: pointer;
    padding: 6.5px 3.5px;
    width: 100%;
}



.containerWithRozmiarMatryca {
    width: 46%;
}
.containerWithLineRange{
    width: 100%
}

.colorContainerMatrcya{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.colorBlockMatryca{
    width: 100px; 
    height: 130px; 
    flex-shrink: 0; 
    flex-grow: 0; 

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    margin-left:3px;
}
.blockWithPhotosColor{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.colorImageMatryca{
    width: 100px;
    height: 100px;  
    border: 1px solid black;
}
.colorImageMatryca.selected {
    border: 3px solid rgb(150, 50, 72);
    margin: 0px;
}
.colorImageMatryca.notSelected {
    margin: 2px;
}

.colorBlockMatryca.disabled {
    cursor: not-allowed; 
    opacity: 0.18; 
    pointer-events: none; 
}

.blockWithPhotosColor label {
    margin-top: 5px;  
}


.imageOptionsLokalizacja {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.imageOptionLokalizacja {
    cursor: pointer;
    display: flex;
    align-items: center;

}

.containerWithOneElemtLokalizacji{
    display: flex;
    flex-direction: column;
    align-items:center;
}

.imgElementLokalizacji{
    border: 1px solid rgb(82, 79, 79);
    height: 100px;
    width: 100px;
}

.imgElementLokalizacji.selected {
    border: 3.5px solid rgb(150, 50, 72);
    margin: 0px;
}
.imgElementLokalizacji.notSelected {
    margin: 2px;

}


.containerWithInputsSizeMatryca{
    display: flex;
    flex-direction: column;
    width: 22%;
    margin-right: 4%;
}

.containerWithRozmiaCzcionkiElementsMatryca{
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 75%;
    background-color: white;
}
.blockWithMinusMatryca{
    width: 100%;
    font-size: 27px;
    border-right: 1px solid black;
    background-color: #FEFAF6;
}
.blockWithMinusMatryca :hover{
    background-color:#F4F2F1;
}
.blockWithMinusMatryca span {
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items:center;
}
.containerWithInputCzcionkaRozmiarMatryca{
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerWithPlusMatryca{
    width: 100%;
    font-size: 27px;
    border-left: 1px solid black;
    background-color: #FEFAF6;
}
.containerWithPlusMatryca span{
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items:center;
}

.containerWithPlusMatryca :hover{
    background-color:#F4F2F1;

}