.containerWizualizatorPhotopanel{

    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    height: 100%;
}

.reducedContainerWithPhoto{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: absolute;
}

.containerPhotoPhotopanel img{
    width: 100%;
    height: 100%;
}