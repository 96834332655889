.cameoOptions{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.cameoOption {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.cameoOkeinkoOne {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cameoOkeinkoOne img {
    border: 1px solid rgb(82, 79, 79);
    width: 100px;
    height: 100px; 
}

.cameoOkeinkoOne img.selected {
    border: 3px solid rgb(150, 50, 72);
    margin: 0px;
}

.cameoOkeinkoOne img.notSelected {
    margin: 2px;
}

.cameoOkeinkoOne span {
    margin-top: 5px;
}


.opcjaOkienkaContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 15px; 
}

.opcjaOkienkaOption {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.opcjaOkienkaOne {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.opcjaOkienkaOne img {
    border: 1px solid rgb(82, 79, 79);
    width: 100px;
    height: 100px; 
}

.opcjaOkienkaOne img.selected {
    border: 3px solid rgb(150, 50, 72);
    margin: 0px;
}
.opcjaOkienkaOne img.notSelected {
    margin: 2px;
}

.opcjaOkienkaOne span {
    margin-top: 5px;
}

.containerWithButtonZaladujPlik{
    margin-top: 16px;
}