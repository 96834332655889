.grzbietAlone{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 7%;
    position: relative;
    height: auto;
}

.grzbiet_with_Components{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 7%;
    position: relative;
    height: 100%;
    margin-right: 10px; 
}

.content_text_grzbiet{
    position: absolute;
    writing-mode: vertical-rl;
    font-size: 16px;
    text-align: center;
}


.img_grzbiet_with_components {

    height: 100%;
    width: 100%;
    object-fit: cover;
}

.img_grzbiet_alone{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.content_text_grzbiet{
    display: flex;
    width: 100%;
    align-items:center;
}


.center_grzbiet {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dol_grzbiet {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.font_grzbiet{
    font-size: 18px;
    font-family: 'futura_ptbook';
}
.white{
    color:#ffff;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}

.plain{
    color: transparent;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}

.gold{
    color: #c6aa6a;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(at right bottom, rgb(70, 37, 35) 0px, rgb(203, 155, 81) 22%, rgb(246, 226, 122) 45%, rgb(246, 242, 192) 50%, rgb(246, 226, 122) 55%, rgb(203, 155, 81) 78%, rgb(70, 37, 35) 100%);
}

.silver{
    color: #babbbe;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}

.copper{
    color:#b46b3d;
    background-color: transparent;
    text-shadow: rgba(255, 255, 255, 0.2) 1px 1px 1px, rgba(0, 0, 0, 0.4) -1px -1px 1px;
}





@media screen and (max-width: 1000px) {
    .content_text_grzbiet{
        font-size: 12px;
    }
}

@media screen and (max-width: 600px) {
    .content_text_grzbiet{
        font-size: 8px;
    }
}