.container_wizualizator {
    display: flex;
    width: 96%;
    /* izmenil tut */
    /* height: auto;  */

    height: 96%;
    overflow: hidden;
    
}

.container_with_image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 96vh;  */
    height: 96%; 

    margin: 10px;
}
.container_with_contents{
    position: relative; 
    display: flex; 
    width: 100%;
    height: auto; 
    justify-content: center;
    align-items: center;
}

.photo_main {
    width: 100%;
    height: auto;
}

