body {
  margin: 0;
  padding: 0;
  font-family: 'MavenPro';
}

select {
  font-family: 'MavenPro';
  border: 1px solid black;
  border-radius: 0px;
}
input{
  font-family: 'MavenPro';
  border-radius:0;
  border: 1px solid black;
}
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.ReactModal__Content {
  padding: 0 !important;
}
.ReactModal__Overlay--after-open{
 background-color: rgb(168, 162, 162) !important;
}





@font-face{
  font-family:futura_pt_condXBdOb;src:url(assets//15364-webfont.woff2) format("woff2"),url(assets//15364-webfont.woff) format("woff"),url(assets//15364-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_pt_condmedium_oblique;src:url(assets//15360-webfont.woff2) format("woff2"),url(assets//15360-webfont.woff) format("woff"),url(assets//15360-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_pt_condbook;src:url(assets//15357-webfont.woff2) format("woff2"),url(assets//15357-webfont.woff) format("woff"),url(assets//15357-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlinemedium;src:url(assets//46885-webfont.woff2) format("woff2"),url(assets//46885-webfont.woff) format("woff"),url(assets//46885-webfont.ttf) format("truetype");font-weight:500;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlineXBdIt;src:url(assets//46881-webfont.woff2) format("woff2"),url(assets//46881-webfont.woff) format("woff"),url(assets//46881-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_pt_condextra_bold;src:url(assets//15363-webfont.woff2) format("woff2"),url(assets//15363-webfont.woff) format("woff"),url(assets//15363-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_pt_condbold_oblique;src:url(assets//15362-webfont.woff2) format("woff2"),url(assets//15362-webfont.woff) format("woff"),url(assets//15362-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_pt_condbold;src:url(assets//15361-webfont.woff2) format("woff2"),url(assets//15361-webfont.woff) format("woff"),url(assets//15361-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_pt_condmedium;src:url(assets//15359-webfont.woff2) format("woff2"),url(assets//15359-webfont.woff) format("woff"),url(assets//15359-webfont.ttf) format("truetype");font-weight:500;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_pt_condbook_oblique;src:url(assets//15358-webfont.woff2) format("woff2"),url(assets//15358-webfont.woff) format("woff"),url(assets//15358-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_ptbook_oblique;src:url(assets//10885-webfont.woff2) format("woff2"),url(assets//10885-webfont.woff) format("woff"),url(assets//10885-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlineMdIt;src:url(assets//46886-webfont.woff2) format("woff2"),url(assets//46886-webfont.woff) format("woff"),url(assets//46886-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlinelight;src:url(assets//46883-webfont.woff2) format("woff2"),url(assets//46883-webfont.woff) format("woff"),url(assets//46883-webfont.ttf) format("truetype");font-weight:300;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlineitalic;src:url(assets//46882-webfont.woff2) format("woff2"),url(assets//46882-webfont.woff) format("woff"),url(assets//46882-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlineextrabold;src:url(assets//46880-webfont.woff2) format("woff2"),url(assets//46880-webfont.woff) format("woff"),url(assets//46880-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlinebold_italic;src:url(assets//46879-webfont.woff2) format("woff2"),url(assets//46879-webfont.woff) format("woff"),url(assets//46879-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlinebold;src:url(assets//46878-webfont.woff2) format("woff2"),url(assets//46878-webfont.woff) format("woff"),url(assets//46878-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_ptextra_bold_oblique;src:url(assets//10888-webfont.woff2) format("woff2"),url(assets//10888-webfont.woff) format("woff"),url(assets//10888-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_ptextra_bold;src:url(assets//10887-webfont.woff2) format("woff2"),url(assets//10887-webfont.woff) format("woff"),url(assets//10887-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_ptheavy_oblique;src:url(assets//10882-webfont.woff2) format("woff2"),url(assets//10882-webfont.woff) format("woff"),url(assets//10882-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlineregular;src:url(assets//46887-webfont.woff2) format("woff2"),url(assets//46887-webfont.woff) format("woff"),url(assets//46887-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlinelight_italic;src:url(assets//46884-webfont.woff2) format("woff2"),url(assets//46884-webfont.woff) format("woff"),url(assets//46884-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:dejanire_headlineblack_italic;src:url(assets//46877-webfont.woff2) format("woff2"),url(assets//46877-webfont.woff) format("woff"),url(assets//46877-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_ptlight_oblique;src:url(assets//10883-webfont.woff2) format("woff2"),url(assets//10883-webfont.woff) format("woff"),url(assets//10883-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_ptlight;src:url(assets//10886-webfont.woff2) format("woff2"),url(assets//10886-webfont.woff) format("woff"),url(assets//10886-webfont.ttf) format("truetype");font-weight:300;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_ptbook;src:url(assets//10884-webfont.woff2) format("woff2"),url(assets//10884-webfont.woff) format("woff"),url(assets//10884-webfont.ttf) format("truetype");font-weight:400;font-style:normal;font-display:swap}
  @font-face{
  font-family:futura_pt_medium;src:url(assets//FuturaPT-Medium.woff2) format("woff2"),url(assets//FuturaPT-Medium.woff) format("woff"),url(assets//FuturaPT-Medium.ttf) format("truetype");font-weight:500;font-style:normal;font-display:swap}
  
  
  @font-face {
    font-family: 'MavenPro';
    src: url('assets/MavenPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'OldStandardTT';
    src: url('assets/OldStandardTT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: 'A_Gentle_Touch';
    src: url('assets/A_Gentle_Touch.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Anna_Clara';
    src: url('assets/Anna_Clara.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  } 
  @font-face {
    font-family: 'Argentinian_night';
    src: url('assets/Argentinian_Nights_by_Kestrel_M_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Autumn_in_November';
    src: url('assets/Autumn_in_November.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Avocado_Lime';
    src: url('assets/Avocado_Lime.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Ballerina_script';
    src: url('assets/Ballerina_Script.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Champagne_limousines';
    src: url('assets/Champagne\ &\ Limousines.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Charlotte';
    src: url('assets/charlotte.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Claudia_script';
    src: url('assets/claudia\ script.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Dutch_801_xbd_bt';
    src: url('assets/Dutch801\ XBd\ BT\ Extra\ Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Endolitta_demo';
    src: url('assets/Endolitta\ Demo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Exotc350BdBTBold';
    src: url('assets/Exotc350BdBTBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gardenia';
    src: url('assets/Gardenia.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'HARRINGT';
    src: url('assets/HARRINGT.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Hesterica';
    src: url('assets/Hesterica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'HighTOWERT';
    src: url('assets/HighTOWERT.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'ITCKRIST';
    src: url('assets/ITCKRIST.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'JandaHappyDay';
    src: url('assets/JandaHappyDay.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Juice_itc';
    src: url('assets/juice\ itc.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'KGBeneathYourBeautifulChunk';
    src: url('assets/KGBeneathYourBeautifulChunk.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Love_moment';
    src: url('assets/Love\ Moment.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'March_lovers';
    src: url('assets/March\ Lovers\ -\ Personal\ Use.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mondayline';
    src: url('assets/mondayline.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Munira-script';
    src: url('assets/munira-script.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'My_silly_wully_girl';
    src: url('assets/My\ Silly\ Willy\ Girl.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Nattalia';
    src: url('assets/Nattalia.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'October_moon';
    src: url('assets/October\ Moon\ -\ TTF.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'OptimusPrinceps';
    src: url('assets/OptimusPrinceps.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'RougeScript';
    src: url('assets/RougeScript-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'SALSABILLA';
    src: url('assets/SALSABILLA\ .ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'SIMSUN';
    src: url('assets/SIMSUN.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Spring_is_comming';
    src: url('assets/Spring\ is\ Coming\ -\ TTF.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'stylus_bt';
    src: url('assets/stylus_bt.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Weather_sunday';
    src: url('assets/Weather\ Sunday\ -\ Personal\ Use.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'You_make_me_smile';
    src: url('assets/You\ Make\ Me\ Smile.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'KGNeatlyPrinted';
    src: url('assets/KGNeatlyPrinted.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }