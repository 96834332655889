.modalOverlayActive {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(192, 130, 142, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    z-index: 100000;
  }
  .modalOverlayNotActive {
    transform: scale(0);
  }

  .modalContent {
    background-color: #ffffff;
    padding: 2rem 3.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* max-width: 400px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center
  }
  
  .uwagaInfo{
    font-weight: 600;
    font-size: 1.4em;
  }
  .containerWithButtons{
    display: flex;
    justify-content: center;
    align-items:flex-start;
  }
  .modalOverlayActive button {
    cursor:pointer;
    padding: 8px 7px;
    border: 0.4px solid black;
    background-color: #963248;
    color:rgb(233, 228, 228);
  }
 
  @media screen and (max-width: 800px) {  
    .modalContent {
        padding: 1rem 2rem;  
    }
    .uwagaInfo {
        font-size: 1.2em;  
    }
}