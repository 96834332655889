.blockPersonalizacjaColoru{
    display: flex;
    flex-direction: column;
}

.imagesBlockGrzbiet {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 103px; */

}

.imagesBlockGrzbiet img {
    width: 100px; 
    height: 100px;

    cursor: pointer;
    border: 1px solid rgb(82, 79, 79);

}

.imagesBlockGrzbiet.selected img{
    border: 3px solid rgb(150, 50, 72);
    margin: 0px;
}
.imagesBlockGrzbiet.notSelected img{
    margin: 2px;

}

.blockWithinfoTekstGrzbiet{
    display: flex;
    justify-content: space-between;
}
.mainContainerWithTekstGrzbiet{
    display: flex;
    flex-direction: column;
}
.containerWithInputGrzbiet{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.inputGrzbiet{
    width:100%;
    padding: 7.5px 3.5px;
    cursor:pointer;
    box-sizing: border-box;
}







.colorContainerGrzbiet{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.colorBlockGrzbiet {
    width: 100px; 
    height: 130px; 
    flex-shrink: 0; 
    flex-grow: 0; 

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
}

.blockWithPhotosColorGrzbiet{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 4px;
}

.colorBlockGrzbiet.disabled {
    cursor: not-allowed; 
    opacity: 0.18; 
    pointer-events: none; 
}
.colorImageGrzbiet{
    width: 100px;
    height: 100px;  
    border: 1px solid black;
}
.colorImageGrzbiet.selected {
    border: 3px solid rgb(150, 50, 72);
    margin:0px;
}
.colorImageGrzbiet.notSelected {
    margin:2px;

}

.blockWithPhotosColorGrzbiet label {

    margin-top: 3px;  
}
