.modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    
}
.modal_active {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    transform: scale(1);
    /* overflow: auto; */



    background-color: rgb(254, 250, 246);
    flex-direction: column;

}
.mainContainerWithAllContentKonfigurator{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    height: calc(100vh - 120px); 
    padding-top: 60px; 


    /* overflow: auto; */
}

.headerKonfigurator{
    height: 60px;
    position: fixed;
    top:0;

    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.containerWithButtonsKOnfiguracji{
    height: 60px;


    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
    
}
.mainContainerWithbuttonsKinfiguracji{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.containerWithAllContentRight{
    width: 100%;
  
}
.wrapper {
    width: 100vw;
    height: calc(100vh - 120px);
    display: flex;
}

.wrapperConstruktor{
    width: 50%;
    height: calc(100vh - 120px);

    overflow: auto;
}

.wrapperWizualizator{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
}

.containerWithForm{
    width: 80%;

}



.buttonSaveAllKonfigurator{
    cursor:pointer;
    padding: 8px 7px;
    background-color: #963248;
    font-family: 'MavenPro';
    color: white;
    border: none;
}


.smallLine{
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    display: flex;
    justify-content: center;
}
.containerWithTabs{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-wrap: wrap;
}
.containerWithTabs button{
    cursor:pointer;
    padding: 8px 7px;
    color: white;
    border: none;
    background-color: #963248;
    margin-right: 15px;
    margin-bottom: 15px;
}

.tabOne {
    opacity: 0.6;
}
.activeTab {
    opacity: 1;
}
